'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _from = require('babel-runtime/core-js/array/from');

var _from2 = _interopRequireDefault(_from);

var _defineProperty = require('babel-runtime/core-js/object/define-property');

var _defineProperty2 = _interopRequireDefault(_defineProperty);

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; (0, _defineProperty2.default)(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              * Controls what is exposed to the window and provides an API to be used externally.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              * Consolidates the three configurations:
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              *  - Customer config (window.cxcoconfig).
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              *  - Project config (project.config.js).
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              *  - Global config (exposed VA functions).
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              */


var _window = require('global/window');

var _window2 = _interopRequireDefault(_window);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return (0, _from2.default)(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var debug = require('debug')('cxco:cxco');

var Cxco = function () {
    function Cxco(core) {
        _classCallCheck(this, Cxco);

        // this.functions = functions
        this.core = core;
        this.eventQueue = Array.isArray(_window2.default.cxco) ? _window2.default.cxco : [];
        _window2.default.cxco = {
            push: this.push.bind(this)
        };
        this.processQueue();
    }

    _createClass(Cxco, [{
        key: 'push',
        value: function push(event) {
            this.eventQueue.push(event);
            this.processQueue();
        }
    }, {
        key: 'processQueue',
        value: function processQueue() {
            while (this.eventQueue.length) {
                var event = this.eventQueue.shift();

                this.processAsyncEvent(event);
                debug('processed', event);
            }
        }

        /**
         *
         * @param {Object[]} event
         */

    }, {
        key: 'processAsyncEvent',
        value: function processAsyncEvent() {
            var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

            var method = args.shift();

            switch (method) {
                case 'clearConversation':
                case 'changeState':
                case 'sendEvent':
                case 'showInput':
                case 'on':
                case 'ask':
                    try {
                        var _core;

                        debug('calling ' + method);
                        (_core = this.core)[method].apply(_core, _toConsumableArray(args));
                    } catch (error) {
                        debug('function ' + method + ' not available', error);
                    }
                    break;
                default:
                    debug('couldn\'t find function');
            }
        }
    }]);

    return Cxco;
}();

exports.default = Cxco;