'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Input = undefined;

var _hyperapp = require('hyperapp');

var Input = exports.Input = function Input() {
    return function (state, actions) {
        return (0, _hyperapp.h)(
            'div',
            { 'class': 'cxco-c-input ' + (state.showInput ? '' : 'cxco-u-is-hidden') },
            (0, _hyperapp.h)(
                'div',
                { 'class': 'cxco-c-input__group' },
                (0, _hyperapp.h)('input', { autocomplete: 'off',
                    'class': 'cxco-c-input__field',
                    type: 'text',
                    placeholder: state.inputPlaceholder ? state.inputPlaceholder : 'Your input here',
                    value: state.text,
                    onkeyup: function onkeyup(e) {
                        return actions.onKeyUp(e);
                    },
                    oninput: function oninput(e) {
                        return actions.onInput(e);
                    } }),
                (0, _hyperapp.h)(
                    'button',
                    { 'class': 'cxco-c-input__button', type: 'button',
                        onclick: function onclick(e) {
                            return actions.ask({ question: state.text });
                        } },
                    (0, _hyperapp.h)('i', { 'class': 'cxco-o-icon__paper-plane' })
                )
            )
        );
    };
}; /* eslint-disable no-unused-vars */