'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Faqs = undefined;

var _hyperapp = require('hyperapp');

/**
 * FAQ Component is a speech bubble containing contextual or Page Based FAQs.
 */
var Faqs = exports.Faqs = function Faqs() {
    return function (state, actions) {
        if (state.faqs && state.showFaqs) {
            return (0, _hyperapp.h)(
                'div',
                { 'class': 'cxco-o-list__item',
                    key: 'bubble-faq' },
                (0, _hyperapp.h)(
                    'div',
                    { 'class': 'cxco-c-bubble ' + (state.faqs && state.faqs.length > 0 && state.isLoading ? ' is-fading' : '') },
                    (0, _hyperapp.h)(
                        'div',
                        { 'class': 'cxco-c-faqs' },
                        (0, _hyperapp.h)(
                            'div',
                            { 'class': 'cxco-c-faqs__title' },
                            state.faqsTitle ? state.faqsTitle : 'Or maybe these related questions can help:'
                        ),
                        (0, _hyperapp.h)(
                            'ul',
                            { 'class': 'cxco-c-faqs__list' },
                            state.faqs.map(function (item, idx) {
                                return (0, _hyperapp.h)(
                                    'li',
                                    { onclick: function onclick(e) {
                                            actions.getFaqAnswer(item);
                                        } },
                                    item.question
                                );
                            })
                        )
                    )
                )
            );
        }
    };
}; /* eslint-disable no-unused-vars */