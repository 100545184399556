'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _extends = _assign2.default || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };
/**
 *
 * @param {Object} options - e.g. { adapter }
 * @param {DCXAPIAdapter} options.adapter - adapter to make the request (axios)
 */


exports.default = function (options) {
    return {
        install: function install(core) {
            var instance = void 0;

            try {
                if (!options.adapter) {
                    options.adapter = _axiosAdapter2.default;
                }

                instance = new _DcxApi2.default(options);
            } catch (error) {
                core.error(error);

                return;
            }

            /**
             * OnRequest Callback
             * @param {string} type - Core "event" type (ask, sendEvent, feedback, linkclick)
             * @param {Object} payload - Core Event Payload
             */
            var onRequest = function onRequest(type) {
                return function (payload) {
                    instance.onRequest(_extends({}, payload, { type: type })).then(core.emitAnswerWith(_extends({}, payload, { type: type }))).catch(function (error) {
                        return core.error(error, payload);
                    });
                };
            };

            core.on('ask', onRequest('ask'));
            core.on('dialogstep', onRequest('dialogstep'));
            core.on('event', onRequest('event'));
            core.on('linkclick', onRequest('linkclick'));
            core.on('feedback', onRequest('feedback'));
            core.on('faq', onRequest('faq'));
            core.on('categorytree', onRequest('categorytree'));
            core.on('faqsincategory', onRequest('faqsincategory'));
        }
    };
};

var _DcxApi = require('./DcxApi');

var _DcxApi2 = _interopRequireDefault(_DcxApi);

var _axiosAdapter = require('./adapters/axiosAdapter');

var _axiosAdapter2 = _interopRequireDefault(_axiosAdapter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }