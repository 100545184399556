"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Titlebar = undefined;

var _hyperapp = require("hyperapp");

var Titlebar = exports.Titlebar = function Titlebar() {
    return function (state, actions) {
        return (0, _hyperapp.h)(
            "div",
            { "class": "cxco-c-titlebar",
                onclick: function onclick(e) {
                    return actions.toggle();
                }
            },
            (0, _hyperapp.h)(
                "div",
                { "class": "cxco-c-titlebar__labels" },
                (0, _hyperapp.h)(
                    "div",
                    null,
                    state.title
                ),
                (0, _hyperapp.h)(
                    "div",
                    null,
                    state.subtitle
                )
            ),
            (0, _hyperapp.h)("span", { "class": "cxco-c-titlebar__close",
                "aria-label": "Close",
                onmouseenter: function onmouseenter(e) {
                    return actions.closeMouseEnter();
                },
                onmouseleave: function onmouseleave(e) {
                    return actions.closeMouseLeave();
                }
            })
        );
    };
}; /* eslint-disable no-unused-vars */