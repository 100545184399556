'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.createNewAnswerBubble = exports.createBubbles = undefined;

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _extends = _assign2.default || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var debug = require('debug')('cxco:va-bubbleFactory');

var id = 0;

var createBubbles = exports.createBubbles = function createBubbles(_ref) {
    var data = _ref.data,
        metadata = _ref.metadata;

    var bubbles = [];
    // Create a fake div to make use of the querySelector to split paragraphs
    var fakeDiv = document.createElement('div');

    fakeDiv.innerHTML = data.answer || data.userInput;

    // modify dom structure so all non-paragraph elements will be attached to the previous paragraph or to a new one.
    for (var i = fakeDiv.childElementCount - 1; i >= 0; i--) {
        if (fakeDiv.children[i].nodeName.toLowerCase() === 'p') {
            continue;
        } else {
            /* this appends children to the previous paragraph.
            *  for headers this won't work as they can be the first element BEFORE a paragraph.
            */
            if (i - 1 >= 0 && fakeDiv.children[i - 1].nodeName.toLowerCase() === 'p') {
                // copy every element except UL for dialog options
                try {
                    if (fakeDiv.children[i].nodeName.toLowerCase() !== 'ul' || fakeDiv.children[i].nodeName.toLowerCase() === 'ul' && fakeDiv.children[i].children[0].textContent.indexOf('%{DialogOption(') < 0) {
                        fakeDiv.children[i - 1].appendChild(fakeDiv.children[i]);
                    }
                } catch (error) {
                    debug(error);
                }
            } else {
                var child = fakeDiv.children[i];

                var wrapper = document.createElement('p');

                // set the wrapper as child (instead of the element)
                fakeDiv.replaceChild(wrapper, child);
                // set element as child of wrapper
                wrapper.appendChild(child);
            }
        }
    }

    var paragraphs = fakeDiv.querySelectorAll('p');
    var lastParagraphIndex = paragraphs.length - 1;

    if (metadata.okMessages) {
        metadata.okMessages.forEach(function (msg) {
            bubbles.push(createNewAnswerBubble(msg.text));
        });
    }

    paragraphs.forEach(function (paragraph, i) {
        // the dialog options need to go into a separate item, first we add the question and then the options.
        if (i === lastParagraphIndex && data.dialogOptions && data.dialogOptions.length > 0) {
            bubbles.push(createNewAnswerBubble(paragraph.innerHTML, {
                sessionId: metadata.sessionId,
                interactionId: metadata.interactionId,
                quickReplies: metadata.quickReplies
            }));
        }

        bubbles.push(createNewAnswerBubble(paragraph.innerHTML, {
            sessionId: metadata.sessionId,
            interactionId: metadata.interactionId,
            quickReplies: metadata.quickReplies,
            dialogOptions: i === lastParagraphIndex && data.dialogOptions ? data.dialogOptions.map(function (option) {
                return {
                    text: option,
                    dialogPath: metadata.dialogPath
                };
            }) : []
        }));
    });

    return bubbles;
};

var createNewAnswerBubble = exports.createNewAnswerBubble = function createNewAnswerBubble(text, options) {
    return _extends({
        id: ++id,
        date: Date.now(),
        type: 'answer',
        text: text
    }, options);
};