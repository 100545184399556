'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.view = undefined;

var _hyperapp = require('hyperapp');

var _titlebar = require('./titlebar');

var _conversation = require('./conversation');

var _loader = require('./loader');

var _input = require('./input');

var _faqs = require('./faqs');

var _avatar = require('./avatar');

var _closebutton = require('./closebutton');

// This is the start of the template definitions.

// The Closebuttoncontainer, FooterDialogContainer, GridContainer could have been in seperate files (like the Avatar, input etc. are)
// But they are this tiny and without logic, so that seemed to be overkill
// These partials are only so that partials can be nested in a wrapping container, which cannot be done in the below 'let' statements of the templates themselves


// Closebuttoncontainer because directly putting HTML in the template makes it losing it state after updating the state
/* eslint-disable no-unused-vars */
var ClosebuttonContainer = function ClosebuttonContainer(props, loader) {
    return function (state, actions) {
        return (0, _hyperapp.h)(
            'div',
            { 'class': 'cxco-c-input-closebutton-container' },
            (0, _hyperapp.h)(_closebutton.CloseButton, null),
            (0, _hyperapp.h)(_input.Input, null)
        );
    };
};

// Closebuttoncontainer because directly putting HTML in the template makes it losing it state after updating the state
var FooterDialogContainer = function FooterDialogContainer(props, loader) {
    return function (state, actions) {
        return (0, _hyperapp.h)(
            'div',
            { 'class': 'cxco-c-footerDialogContainer' },
            loader
        );
    };
};

// Closebuttoncontainer because directly putting HTML in the template makes it losing it state after updating the state
var GridContainer = function GridContainer(props, loader) {
    return function (state, actions) {
        return (0, _hyperapp.h)(
            'div',
            { 'class': 'cxco-gridContainer' },
            loader
        );
    };
};

var compactTemplate = [(0, _hyperapp.h)(_avatar.Avatar, null), (0, _hyperapp.h)(_titlebar.Titlebar, null), (0, _hyperapp.h)(
    _conversation.Conversation,
    null,
    (0, _hyperapp.h)(_faqs.Faqs, null),
    (0, _hyperapp.h)(_loader.Loader, null)
), (0, _hyperapp.h)(_input.Input, null)];

var amcInPageTemplate = [(0, _hyperapp.h)(
    _conversation.Conversation,
    null,
    (0, _hyperapp.h)(_faqs.Faqs, null),
    (0, _hyperapp.h)(_loader.Loader, null)
), (0, _hyperapp.h)(ClosebuttonContainer, null)];

var amcPopupTemplate = [(0, _hyperapp.h)(_avatar.Avatar, null), (0, _hyperapp.h)(
    FooterDialogContainer,
    null,
    (0, _hyperapp.h)(
        GridContainer,
        null,
        (0, _hyperapp.h)(
            _conversation.Conversation,
            null,
            (0, _hyperapp.h)(_faqs.Faqs, null),
            (0, _hyperapp.h)(_loader.Loader, null)
        )
    ),
    (0, _hyperapp.h)(ClosebuttonContainer, null)
)];
var evaServiceCenterTemplate = [(0, _hyperapp.h)(_avatar.Avatar, null), (0, _hyperapp.h)(_titlebar.Titlebar, null), (0, _hyperapp.h)(
    _conversation.Conversation,
    null,
    (0, _hyperapp.h)(_faqs.Faqs, null),
    (0, _hyperapp.h)(_loader.Loader, null)
), (0, _hyperapp.h)(_input.Input, null)];
var medimappTemplate = [(0, _hyperapp.h)(
    _conversation.Conversation,
    null,
    (0, _hyperapp.h)(_faqs.Faqs, null),
    (0, _hyperapp.h)(_loader.Loader, null)
), (0, _hyperapp.h)(ClosebuttonContainer, null)];

// End of Template definitions

// List of possible styles, which can be then be chosen in the cxcoconfig
var STYLES = {
    medimapp: medimappTemplate,
    amcInPage: amcInPageTemplate,
    amcPopup: amcPopupTemplate,
    compact: compactTemplate,
    evaServiceCenter: evaServiceCenterTemplate
};

var view = exports.view = function view(state, actions) {
    return (0, _hyperapp.h)(
        'div',
        { 'class': 'cxcoContainer' },
        (0, _hyperapp.h)(
            'div',
            { 'class': 'cxco-o-layout cxco-o-layout--' + (state.style ? state.style : 'compact') + ' ' + (state.isOpen ? ' is-open' : ' is-closed') + ' ' + (state.isHidden ? ' is-hidden' : ''),
                key: 'cxco-chat-window',
                oncreate: function oncreate(e) {
                    actions.botReady();
                    if (state.messages.length === 0) {
                        actions.initVA();
                    }
                },
                onupdate: actions.storeStateInStorage(state) },
            STYLES[state.style]
        )
    );
};