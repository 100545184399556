"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.QuickReplies = undefined;

var _hyperapp = require("hyperapp");

var QuickReplies = exports.QuickReplies = function QuickReplies(_ref) {
    var quickReplies = _ref.quickReplies,
        isActive = _ref.isActive;
    return function (state, actions) {
        if (isActive && quickReplies && quickReplies.length > 0) {
            return (0, _hyperapp.h)(
                "div",
                { "class": "cxco-c-dialog slideInLeft" },
                quickReplies.map(function (qr) {
                    return (0, _hyperapp.h)(
                        "div",
                        { "class": "cxco-c-dialog__option animated fadeInFast delayed",
                            onclick: function onclick() {
                                return actions.ask({ question: qr.text });
                            } },
                        qr.text
                    );
                })
            );
        }
    };
}; /* eslint-disable no-unused-vars */