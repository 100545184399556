'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _marked = require('marked');

var _marked2 = _interopRequireDefault(_marked);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_marked2.default.setOptions({
    gfm: true,
    tables: true,
    sanitize: true,
    smartLists: true,
    headerPrefix: 'cxco'
});

exports.default = {
    install: function install(core) {
        core.before('answer', processAnswer);
    }
};

/**
 * Turn markdown answer to html answer
 *
 * Mutates the answerPayload by reference
 *
 * @param {object} answerPayload
 */

function processAnswer(answerPayload) {
    if (answerPayload.data && answerPayload.data.answer) {
        answerPayload.data.answer = (0, _marked2.default)(answerPayload.data.answer);
        if (typeof cxcoConfig !== "undefined" && typeof cxcoConfig.context !== "undefined" && typeof cxcoConfig.context.dimensions !== "undefined") {
            if (cxcoConfig.context.dimensions.some(function (dim) {
                return dim['ApplicationEnvironment'] === 'medimapp';
            })) {
                replaceTargets(answerPayload.data, answerPayload.metadata, "_blank");
            }
        }
        replaceMedia(answerPayload.data);
        replaceHyperlinks(answerPayload.data, answerPayload.metadata);
    }
}

// Unused function developed during https://jira.gxsoftware.com/jira/browse/AMC-1158 . 
// This function replaces targets, dependant on user location. Use case: when on amc.nl and linking to vumc.nl, automatically change target to blank
function setBlankTargetsOnLinksWhenDifferentHost(data, metadata) {
    var answer = data.answer;
    var currentHostname = window.location.host;
    if (Array.isArray(data.links) && data.links.length > 0) {
        data.links.forEach(function (h) {
            var thisHost = new URL(h.url).host;
            if (currentHostname !== thisHost) {
                h.target = "_blank";
            }
        });
    }
    data.answer = answer;
}
// function replaceAnswerTags (answer, part, result) {
//     answer = replaceDialogOptions(answer, result.dialogPath)
//     answer = replaceHyperlinks(answer, part.links, result.interactionId)

//     return answer
// }

// function replaceDialogOptions (text, path) {
//     const pattern = /%\{DialogOption\((.*?)\)\}/g

//     return text.replace(pattern, (full, match) => {
//         let tpl = dot.template(dialogOptionHTML)

//         return tpl({
//             path: path,
//             text: match
//         })
//     })
// }
function replaceTargets(data, metadata, targetType) {
    var answer = data.answer;
    if (Array.isArray(data.links) && data.links.length > 0) {
        data.links.forEach(function (h) {
            h.target = targetType;
        });
    }
    data.answer = answer;
}
function replaceHyperlinks(data, metadata) {
    var answer = data.answer;

    if (Array.isArray(data.links) && data.links.length > 0) {
        data.links.forEach(function (h) {
            var pattern = new RegExp('%\\{Link\\(' + h.id + '\\)\\}', 'gi');
            var a = '<a href="' + h.url + '" target="' + h.target + '" data-url="' + h.url + '" data-id="' + h.id + '" data-interactionid="' + metadata.interactionId + '" data-dcx-link>' + h.text + '</a>';

            answer = answer.replace(pattern, a);
        });
    }

    data.answer = answer;
}

/**
 * Replaces Images and Videos
 * @param {Object} data
 */
function replaceMedia(data) {
    replaceImages(data);
    replaceVideos(data);
}

/**
 * Replaces images in answers
 * @param {Object} engineResult
 */
function replaceImages(data) {
    if (Array.isArray(data.images)) {
        data.images.forEach(function (img) {
            var additionalData = img.additionalData || {};

            // get the factor the image should be sized to
            var sizeFactor = additionalData.imageWidth ? parseFloat(img.additionalData.imageWidth) : 1;

            // get the alignment class (left/center/right)
            var alignment = additionalData.imageAlign ? img.additionalData.imageAlign : 'left';

            // convert it to a percentage
            var imageWidth = sizeFactor * 100;
            var url = img.name;
            // replace the image tags in the content
            var pattern = new RegExp('%\\{Image\\(' + img.id + '\\)\\}', 'gi');
            var imgHtml = '<img src="' + url + '"\n                            data-src="' + url + '"\n                            title="' + (img.title || '') + '"\n                            alt="' + (img.title || '') + '"\n                            class="cxco-lightbox ' + alignment + '"\n                            width="' + imageWidth + '%" />';

            data.answer = data.answer.replace(pattern, imgHtml);
        });
    }
}

/**
 * Replaces the video placeholder by the actual video
 * @param {Object} data
 */
function replaceVideos(data) {
    // default dimensions of a video
    var defaultHeight = 360;

    if (Array.isArray(data.videos)) {
        data.videos.forEach(function (video) {
            var additionalData = video.additionalData || {};

            // get the alignment class (left/center/right)
            var alignment = additionalData.videoAlign ? video.additionalData.videoAlign : 'left';

            // get the videoId
            var videoId = additionalData.videoId ? video.additionalData.videoId : '';

            // set the proper embed url based on the source
            var url = '';

            if (video.source.toLowerCase() === 'youtube') {
                url = 'https://www.youtube.com/embed/' + videoId;
            } else if (video.source.toLowerCase() === 'youtubenocookie') {
                url = 'https://www.youtube-nocookie.com/embed/' + videoId;
            } else if (video.source.toLowerCase() === 'vimeo') {
                url = 'https://player.vimeo.com/video/' + videoId;
            }

            // replace the video tags in the content
            var pattern = new RegExp('%\\{Video\\(' + video.id + '\\)\\}', 'gi');
            var videoHtml = '<iframe src="' + url + '"\n                                class="' + alignment + '"\n                                style="height:' + defaultHeight + 'px;width:' + Number(additionalData.videoWidth) * 100 + '%;"\n                                frameborder="0"\n                                allowfullscreen="allowfullscreen"\n                                mozallowfullscreen="mozallowfullscreen" \n                                msallowfullscreen="msallowfullscreen" \n                                oallowfullscreen="oallowfullscreen" \n                                webkitallowfullscreen="webkitallowfullscreen"></iframe>';

            data.answer = data.answer.replace(pattern, videoHtml);
        });
    }
}