'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.htmlAnswers = undefined;

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _extends = _assign2.default || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _htmlAnswers = require('./plugins/htmlAnswers');

Object.defineProperty(exports, 'htmlAnswers', {
    enumerable: true,
    get: function get() {
        return _interopRequireDefault(_htmlAnswers).default;
    }
});

exports.default = function (elm, customization) {
    /**
     * Gets the properties defined in the config that should be applied to the state.
     * These override the storage settings.
     * @param {Object} config
     */
    var getStateSettings = function getStateSettings(config) {
        var settings = {};

        try {
            if (config.layout && config.layout.initialState) {
                switch (config.layout.initialState.toLowerCase()) {
                    case 'closed':
                        settings.isOpen = false;
                        settings.isHidden = true;break;
                    case 'minimized':
                        settings.isOpen = false;
                        settings.isHidden = false;break;
                    case 'opened':
                    default:
                        settings.isOpen = true;
                        settings.isHidden = false;break;
                }
            }

            (0, _keys2.default)(DEFAULT_LAYOUT_MODES).forEach(function (key) {
                if (config.layout && config.layout.hasOwnProperty(key) && key !== 'initialState') {
                    debug('overrided layout mode setting: ' + key);
                    settings[key] = config.layout[key];
                }
            });

            // Copy these keys from config.layout with fallback on defaultConfig
            (0, _keys2.default)(defaultLayoutLabels).forEach(function (key) {
                if (config.layout && config.layout.hasOwnProperty(key)) {
                    debug('overrided layout setting: ' + key);
                    settings[key] = config.layout[key];

                    return;
                }

                if (defaultLayoutLabels.hasOwnProperty(key)) {
                    debug('default layout setting: ' + key);
                    settings[key] = defaultLayoutLabels[key];
                }
            });
        } catch (error) {
            console.error(error);
        }

        return settings;
    };

    return {
        install: function install(core) {
            var actions = (0, _actions.actions)(core);
            var loadedState = void 0;

            try {
                var storedState = core.getState();

                loadedState = _extends({}, storedState, getStateSettings(core.config));
            } catch (e) {
                console.error('error loading from storage or config');
                loadedState = getStateSettings(core.config);
            }

            var view = void 0;

            // verify if view should be chatbot or inline
            if (loadedState.style === 'inline') {
                view = _views.inlineView;
                elm.classList.add('cxco--inline');
            } else {
                view = _views.view;
            }

            // object spread was not working here as expected thus Object.assign to the rescue
            var chatApp = (0, _hyperapp.app)((0, _assign2.default)(_state.state, loadedState), actions.actions, view, elm);

            core.on('answer', chatApp.onAnswer);

            core.on('showInput', chatApp.showInput);

            core.on('changeState', chatApp.changeState);

            core.on('clearConversation', chatApp.clearConversation);
        }
    };
};

require('./themes/default/index.scss');

var _hyperapp = require('hyperapp');

var _actions = require('./actions/');

var _state = require('./state/');

var _views = require('./views/');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var debug = require('debug')('cxco:ui-chat');

/**
 * translatable/customizable content
 */
var defaultLayoutLabels = {
    'title': 'Title',
    'subtitle': 'Subtitle',
    'inputPlaceholder': 'Stel me een vraag',
    'faqsTitle': 'Maybe these related questions can help:',
    'dialogChooseAgain': 'Herstel keuze',
    'dialogNewChoice': 'Nieuw onderwerp'

    /**
     * initialState: 'opened' | 'minimized' | 'closed'
     */
};var DEFAULT_LAYOUT_MODES = {
    'showInput': true,
    'style': 'normal',
    'initialState': 'opened',
    'template': 'compact',
    'scrollType': 'question'
};