// @todo: this should only import the default VA and start it
import CxcoCore from '@cxco/core'
import cxcoChatWidget, { htmlAnswers } from '@basicorange/ui-chat'
import cxcoDcxApi from '@cxco/api-dcx'
import { state } from '../basicorange-ui-chat/lib/state/index.js';
import packageDetails from './package.json';
console.log(`current chatbot version: ${packageDetails.version} and BO variant version: ${packageDetails.boVariantVersionNumber}`);
require('./domready.js');


    // Default settings for cxco config
    // Normally these settings ARE NOT hardcoded in the project, but because we cannot always be sure that the chatbot is implemented in the right way
    // we just set some default settings.
    // In VA-evaEpic you can find an example of how below settings can be overriden
    let AMCCONFIG = {
        /* AMC Development Environment */
        // Project settings can be obtained by loggin in to CX CMS and add "/overview to the end of the url".
        // An example of such a conversion of the url would be:
        // https://login.digitalcx.com/#AMC/EVA/nl/tasks -> https://login.digitalcx.com/#AMC/EVA/nl/overview
        project: {
            publicApiKey: 'd238b20a-4c7c-4b8a-9b79-f2a349e733eb',
            culture: 'nl',
            customerKey: 'AMC',
            key: 'development'
        },
        layout: {
            scrollType: 'answer'
        },
        page: {
            onloadEvent: false
        }
    };
    let totalConfig = Object.assign(AMCCONFIG, window.cxcoConfig);
    const instance = new CxcoCore({ config: AMCCONFIG, useWindowConfig: false, useWindowApi: true })


// DomReady is not originally part of the cxco-default-va but added as a bit of an hacky way, to interact with parts outside of the 
// chatbot. This happens for instance on the amc.nl homepage with the buttons, which trigger chatbot.
// Normally this would be done by adding javascript actions directly on the buttons, but GX did not have the option to create the custom HTML for that
domReady(function () {
    const cxcoRootElement = document.createElement('div')
    let parentElement = null;
    
    // if parentSelector defined inject chatbot inside
    if (instance.config && instance.config.parentSelector) {
        parentElement = document.querySelector(instance.config.parentSelector)
    }
    
    if (parentElement !== null) {
        parentElement.appendChild(cxcoRootElement)
    } else {
        window.cxcoRootElement = cxcoRootElement;
    }

    let currentStyle = null;
    if (instance.config && instance.config.layout && instance.config.layout.style) {
        currentStyle = instance.config.layout.style;
    }
    else {
        currentStyle = "noTemplate";
    }

    cxcoRootElement.classList.add('cxco')
    document.body.classList.add('cxco-template-' + currentStyle)
    cxcoRootElement.classList.add('cxco-template-' + currentStyle) //possibly not necessary when were adding classes to body
    
    instance.use(htmlAnswers)
    instance.use(cxcoChatWidget(cxcoRootElement))
    instance.clearConversation() // By default empty earlier conversation.
    instance.use(cxcoDcxApi({}))  

    cxco.push([
        'on',
        'ask',
        function (result) {
            cxco.push(['changeState', { isOpen: true }])
            document.body.classList.add("cxco-chatbot-active")
        }
    ])

    cxco.push([
        'on', 
        'answer', 
        function(result){
        // this answer originates in the show_feedback event

        if (typeof cxcoConfig !== "undefined" && typeof cxcoConfig.context !== "undefined" && typeof cxcoConfig.context.dimensions !== "undefined"){
            if (cxcoConfig.context.dimensions.some(dim => dim['Omgeving'] !== 'BasicOrange_Test') || cxcoConfig.context.dimensions.some(dim => dim['ApplicationEnvironment'] === 'medimapp') ) {
                if(result.metadata.originalRequest.data.eventName && result.metadata.originalRequest.data.eventName === "show_feedback") {
                    result.data.answer = ""
                    result.data.dialogOptions = []
                    delete result.metadata.dialogPath
                    delete result.metadata.isDialogStart
                    delete result.metadata.isDialogEnd
                }
            }
        }else if(typeof cxcoConfig !== "undefined" && typeof cxcoConfig.context === "undefined"){
            if(result.metadata.originalRequest.data.eventName && result.metadata.originalRequest.data.eventName === "show_feedback") {
                result.data.answer = ""
                result.data.dialogOptions = []
                delete result.metadata.dialogPath
                delete result.metadata.isDialogStart
                delete result.metadata.isDialogEnd
            }
        }        
    }])

    // Logic of GX amc.nl homepage buttons
    if (currentStyle === "amcInPage") {
        
        // cxcoTriggerElements are the buttons on amc.nl Homepage
        const cxcoTriggerElements = document.querySelectorAll('.cxco-trigger')
        // let formElm = document.getElementById("cxcoInpageContainer")
        
        let buttonContainer = document.getElementById("cxcoInPageButtons")
        let bottomOfButtonContainer = buttonContainer.offsetTop + buttonContainer.getBoundingClientRect().height
        
        for (let cxcoTriggerElement of cxcoTriggerElements) {
            cxcoTriggerElement.addEventListener('click', function(event){
                document.body.classList.add("cxco-chatbot-active")
                let heightStickyFooterInput = document.querySelectorAll('.cxco-c-input-closebutton-container')[0].getBoundingClientRect().height
                let valueToScroll = window.outerHeight;

                window.scrollTo(0,valueToScroll);
                valueToScroll = window.outerHeight; // Try to force redraw after scroll for sticky elements
                cxco.push(['clearConversation', {startFromOnloadEvent: false, doRequest: false}]);
                var elm = event.currentTarget;
                var trigger = elm.getAttribute('data-trigger')
                cxco.push(['changeState', { isOpen: true }])
                instance.sendEvent(trigger);
                event.preventDefault();
            });
        }
    }

});