'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _extends = _assign2.default || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = buildRequest;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 *
 * @param {*} eventPayload
 * @returns Object
 */

function buildRequest(eventPayload) {
    var baseUrl = eventPayload.config.baseUrl;

    switch (eventPayload.type) {
        case 'ask':
            return getAskRequest(baseUrl, eventPayload);
        case 'dialogstep':
            return getDialogStepRequest(baseUrl, eventPayload);
        case 'event':
            return getEventRequest(baseUrl, eventPayload);
        case 'linkclick':
            return getLinkclickRequest(baseUrl, eventPayload);
        case 'feedback':
            return getFeedbackRequest(baseUrl, eventPayload);
        case 'faq':
            return getFaqRequest(baseUrl, eventPayload);
        case 'faqsincategory':
            return getFaqsByCatIdRequest(baseUrl, eventPayload);
        case 'categorytree':
            return getCategoriesTreeStructure(baseUrl, eventPayload);
    }

    console.error('not supported payload', eventPayload);

    return null;
}

function getAskRequest(baseUrl, eventPayload) {
    var query = _extends({}, getRequiredQueryParams(eventPayload), {
        q: eventPayload.data.userInput
    });

    if (eventPayload.data.dialogPath) {
        query['session.dialogPath'] = eventPayload.data.dialogPath;
    }

    attachOptionalQueryParams(query, eventPayload);

    return {
        method: 'get',
        url: baseUrl + '/ask',
        query: query
    };
}

function getDialogStepRequest(baseUrl, eventPayload) {
    var query = _extends({}, getRequiredQueryParams(eventPayload), {
        path: eventPayload.data.dialogPath
    });

    attachOptionalQueryParams(query, eventPayload);

    return {
        method: 'get',
        url: baseUrl + '/dialogstep',
        query: query
    };
}

function getEventRequest(baseUrl, eventPayload) {
    var query = getRequiredQueryParams(eventPayload);

    attachOptionalQueryParams(query, eventPayload);

    return {
        method: 'get',
        url: baseUrl + '/event/' + eventPayload.data.eventName,
        query: query
    };
}

function getLinkclickRequest(baseUrl, eventPayload) {
    var _eventPayload$data = eventPayload.data,
        linkId = _eventPayload$data.linkId,
        linkUrl = _eventPayload$data.linkUrl,
        interactionId = _eventPayload$data.interactionId;


    return {
        method: 'post',
        url: baseUrl + '/linkclick',
        data: {
            originInteractionId: interactionId,
            url: linkUrl,
            urlId: linkId
        },
        query: getRequiredQueryParams(eventPayload)
    };
}

function getFeedbackRequest(baseUrl, eventPayload) {
    var _eventPayload$data2 = eventPayload.data,
        score = _eventPayload$data2.score,
        label = _eventPayload$data2.label,
        comment = _eventPayload$data2.comment,
        interactionId = _eventPayload$data2.interactionId;


    return {
        method: 'post',
        url: baseUrl + '/feedback',
        data: {
            originInteractionId: interactionId,
            score: score,
            label: label,
            comment: comment
        },
        query: getRequiredQueryParams(eventPayload)
    };
}

/**
 * Builds Request to get an FAQ output
 * @param {string} baseUrl
 * @param {Object} eventPayload
 */
function getFaqRequest(baseUrl, eventPayload) {
    var query = getRequiredQueryParams(eventPayload);

    return {
        method: 'get',
        url: baseUrl + '/faqs/' + eventPayload.data.faqId + '/output',
        query: query
    };
}

/**
 * Builds Request to get FAQs from a given CategoryId
 * @param {string} baseUrl
 * @param {object} eventPayload
 */
function getFaqsByCatIdRequest(baseUrl, eventPayload) {
    var query = getRequiredQueryParams(eventPayload);

    attachOptionalQueryParams(query, eventPayload);

    /* remove classification id from query to avoid confusion
        * between page related faqs and contextual faqs */
    if (query.classificationId) {
        delete query.classificationId;
    }

    return {
        method: 'get',
        url: baseUrl + '/classifications/' + eventPayload.data.classificationId + '/categories/' + eventPayload.data.categoryId + '/faqs',
        query: query
    };
}

/**
 * Builds Request to get tree structure of the categories.
 * e.g. Get categories for a Classification containing the Page Related FAQs.
 * @param {string} baseUrl
 * @param {Object} eventPayload
 */
function getCategoriesTreeStructure(baseUrl, eventPayload) {
    var query = getRequiredQueryParams(eventPayload);

    attachOptionalQueryParams(query, eventPayload);

    /* remove classification id from query to avoid confusion
    * between page related faqs and contextual faqs */
    if (query.classificationId) {
        delete query.classificationId;
    }

    return {
        method: 'get',
        url: baseUrl + '/classifications/' + eventPayload.data.classificationId + '/categories',
        query: query
    };
}

function getRequiredQueryParams(eventPayload) {
    return {
        apiKey: eventPayload.config.apiKey,
        culture: eventPayload.config.culture,
        'session.id': eventPayload.metadata.sessionId
    };
}

/**
 * Adds optional parameters to the query object.
 * @param {Object} query
 * @param {Object} eventPayload
 */
function attachOptionalQueryParams(query, eventPayload) {
    if (eventPayload.metadata) {
        if (eventPayload.metadata.classificationId) {
            query.classificationId = eventPayload.metadata.classificationId;
        }
        if (eventPayload.metadata.minFaqs) {
            query.minFaqs = eventPayload.metadata.minFaqs;
        }
        if (eventPayload.metadata.maxFaqs) {
            query.maxFaqs = eventPayload.metadata.maxFaqs;
        }
        if (eventPayload.metadata.tDialogState) {
            query.tDialogState = eventPayload.metadata.tDialogState;
        }

        if (eventPayload.metadata.hasOwnProperty('includeFaqs')) {
            query.includefaqs = eventPayload.metadata.includeFaqs;
        }

        if (eventPayload.metadata.dimensions && Array.isArray(eventPayload.metadata.dimensions)) {
            eventPayload.metadata.dimensions.forEach(function (dimension) {
                // TODO this could support adding more dimensions at once
                var newDim = (0, _entries2.default)(dimension)[0];

                query['dim.' + newDim[0]] = newDim[1];
            });
        }

        if (eventPayload.metadata.hasOwnProperty('sessionMode')) {
            query['session.mode'] = eventPayload.metadata.sessionMode;
        }
    }
}