'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.filterPageRelatedFAQs = exports.rafSmoothScroll = undefined;

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _from = require('babel-runtime/core-js/array/from');

var _from2 = _interopRequireDefault(_from);

exports.scrollElement = scrollElement;
exports.delay = delay;
exports.addLightBoxListeners = addLightBoxListeners;
exports.showFeedback = showFeedback;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var raf = require('raf');
var window = require('global/window');
var basicLightBox = require('basiclightbox');
var debug = require('debug')('cxco:utils');

var now = window.performance ? window.performance.now.bind(window.performance) : function () {
    return new Date().getTime();
};

/**
 * Scrolls an element into the visible part of the conversation.
 * When scrollOptions.type = 'question' the questions should always scroll but
 * shouldn't go out of the viewport in case of an answer with several bubbles.
 * When scrollOptions.type = 'answer'
 * Selected dialogs aren't questions nor top level elements (cxco-o-list__item) so we cannot apply a scroll to them.
 *
 * @param {Element} $el DOM element that should be scrolled
 * @param {string} type chat element type (question, answer, feedback)
 * @param {Object} scrollOptions scroll behavior, speed, etc...
 */
function scrollElement($el, type) {
    var scrollOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var $scrollableElement = $el.parentElement;
    var scrollToBottom = scrollOptions.type === 'answer';

    if (type === 'question') {
        smoothScrollIntoView($el);
    }

    if (type === 'answer') {
        var eleToScroll = scrollToBottom ? $el : getLastQuestionOrSelectedDialog();

        if (eleToScroll) {
            if (eleToScroll.offsetTop > $scrollableElement.scrollTop) {
                smoothScrollIntoView(eleToScroll);
            }
        }
    }

    addLightBoxListeners($el);
}

/**
 * Gets the last Question Element or Selected Dialog so it can scroll to it.
 */
var getLastQuestionOrSelectedDialog = function getLastQuestionOrSelectedDialog() {
    return (0, _from2.default)(document.querySelectorAll('.cxco-o-list__item')).reverse().find(function (element) {
        return element.querySelectorAll('.cxco-c-bubble--inverse').length > 0 || element.querySelectorAll('.cxco-c-dialog.selected').length > 0;
    });
};

/**
 * Scrolls bubble element into visible area in the conversation.
 * @param {Element} $el
 */
var smoothScrollIntoView = function smoothScrollIntoView($el) {
    try {
        var topPos = $el.offsetTop;

        // take 10 pixels from the top to leave a margin from the titlebar
        $el.parentElement.scrollTop = topPos - 10;
    } catch (error) {
        // in case browser doesnt support options and decides to throw.
        $el.scrollIntoView(true);
    }
};

/**
 * Uses raf (requestAnimationFrame) to do smoth scroll with easing
 *
 * @param {Element} $scrollable DOM element to be scrolled
 * @param {number} distance the final variation for the scrollTop position
 * @param {number} duration the animations duration
 */
var rafSmoothScroll = exports.rafSmoothScroll = function rafSmoothScroll($scrollable, distance) {
    var duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 250;

    var start = now();

    raf(function doScroll(timestamp) {
        var progress = (timestamp - start) / duration;
        var eased = progress; // easing can go here

        $scrollable.scrollTop += distance * eased;

        if (eased < 1) {
            raf(doScroll);
        } else {
            // make sure we are at the bottom
            $scrollable.scrollTop += distance;
        }
    });
};

/**
 * Delays the output to next() for _delay milliseconds
 *
 * @param {number} _delay seconds of delay
 */
function delay(_delay) {
    return function (value) {
        return new _promise2.default(function (resolve, reject) {
            setTimeout(function () {
                resolve(value);
            }, _delay);
        });
    };
}

function addLightBoxListeners(outputElement) {
    outputElement.querySelectorAll('.cxco-lightbox').forEach(function (element) {
        element.addEventListener('click', function (e) {
            try {
                basicLightBox.create(e.target.outerHTML).show();
            } catch (e) {
                console.error(e);
            }
        });
    });
}

/**
 * Searches for a Category in a Category List.
 * @param {*} categories
 * @param {string} categoryToSearch
 */
var findInCategories = function findInCategories(categories, categoryToSearch) {
    // if the category name includes the domain name it's stripped so only paths are used
    return categories.find(function (cat) {
        return cat.name.replace(new RegExp(window.location.origin, 'i'), '') === categoryToSearch;
    });
};

/**
 * Searches for a Category in a Category List and it's subcategories.
 * @param {Object[]} categories
 * @param {string} categoryName - When using a url should be a pathname (e.g.: '/', '/contact/', '/about/)
 */
var filterPageRelatedFAQs = exports.filterPageRelatedFAQs = function filterPageRelatedFAQs(categories, categoryName) {
    var category = void 0;

    // First Level
    if (/^(http|https):\/\//.test(categories[0].name)) {
        category = findInCategories(categories, categoryName) || {};
    } else {
        // FAQs in sub-categories
        category = categories.reduce(function (acc, curr) {
            var subCat = findInCategories(curr.subCategories, categoryName);

            if (subCat) {
                acc = subCat;
            }

            return acc;
        }, {});
    }

    return category.items;
};

/**
 * Checks if a answer comes from an event by looking into the request that originated it.
 * @param {Object} metadata
 */
function isEvent(metadata) {
    try {
        return metadata.originalRequest.data.hasOwnProperty('eventName');
    } catch (err) {
        debug(err);

        return false;
    }
}

/**
 * Checks if engineResult response should trigger feedback
 *
 * @param {object} engineResult
 */
function showFeedback(_ref) {
    var data = _ref.data,
        metadata = _ref.metadata;

    // events
    if (isEvent(metadata)) {
        return false;
    }

    if (data.outputAdditions && data.outputAdditions.hasOwnProperty('hideFeedback')) {
        var hideFeedback = data.outputAdditions['hideFeedback'] === 'true';

        return !hideFeedback;
    }

    if (typeof metadata.escalation !== 'undefined') {
        return false;
    }

    // faq outputs
    if (data.type === 'answer' && ['faq'].indexOf(metadata.originalRequest.type) >= 0) {
        return false;
    }
    // dialogs and tdialogs
    if (['dialog', 'tdialog'].indexOf(data.type) >= 0 && !metadata.isDialogEnd) {
        return false;
    }

    // unknown
    if (typeof data.type === 'undefined') {
        return false;
    }
    // categories
    if (data.type === 'category') {
        return false;
    }
    // faq list
    if (data.type === 'faq') {
        return false;
    }

    return true;
};