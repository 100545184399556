"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
var state = exports.state = {
    // chatbox is the full window
    isOpen: true,
    isLoading: false,
    showInput: true,
    style: "compact",
    // input:
    text: '',
    // conversation: {
    messages: [],
    livechat: {
        // Livechat options can go here
    }
};