"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.CloseButton = undefined;

var _hyperapp = require("hyperapp");

var CloseButton = exports.CloseButton = function CloseButton() {
    return function (state, actions) {
        return (0, _hyperapp.h)("i", { "class": "cxco-o-icon__times cxco-c-close-button",
            onclick: function onclick(e) {
                actions.toggle();
                if (state.style === "medimapp") {
                    actions.clearConversation({ startFromOnloadEvent: true, doRequest: true });
                } else if (state.isOpen === true) {
                    actions.clearConversation({ startFromOnloadEvent: true, doRequest: false });
                }
                if (state.style === "amcInPage") {
                    var cxcoInpageButtons = document.getElementById("cxcoInPageButtons");
                    window.scrollTo(0, cxcoInpageButtons.offsetTop - 16);
                }
                document.body.classList.toggle("cxco-chatbot-active");
            }
        });
    };
}; /* eslint-disable no-unused-vars */