'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.store = undefined;

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

exports.getStorage = getStorage;
exports.setStorage = setStorage;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var store = exports.store = {
    GENERAL: 'cxco.general',
    SESSION_ID: 'cxco.sessionId'
};

function getStorage(storeName) {
    return JSON.parse(sessionStorage.getItem(storeName));
}

function setStorage(storeName, value) {
    if (value) {
        sessionStorage.setItem(storeName, (0, _stringify2.default)(value));
    }
}