'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.BubbleAvatar = exports.NewTopicDivider = exports.ResetDialog = exports.DialogOptions = exports.Bubble = exports.Conversation = undefined;

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _hyperapp = require('hyperapp');

var _utils = require('../utils');

var _quickReplies = require('./quickReplies');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Conversation component controls the conversation organizing Q&A in Bubble components.
 * @param {*} param0
 * @param {*} children - Children Composition. receives Loader componen by argument
 */
var Conversation = exports.Conversation = function Conversation(props, loader) {
    return function (state, actions) {
        return (0, _hyperapp.h)(
            'div',
            { 'class': 'cxco-o-list', 'data-contents': '' + state.messages.length,
                oncreate: function oncreate(e) {
                    if (state.style !== "compact" && state.isOpen === true && state.isHidden === false) {
                        console.log("Conversation create - style is compact or medimapp");
                        (0, _utils.scrollElement)(e, "answer", {
                            type: state.scrollType
                        });
                    }
                } },
            state.messages.map(function (item, idx) {
                var isActive = idx + 1 === state.messages.length;

                if (item.dialogOptions && item.dialogOptions.length > 0) {
                    return (0, _hyperapp.h)(
                        DialogOptions,
                        {
                            dialogOptions: item.dialogOptions,
                            bubbleIdx: idx,
                            key: idx,
                            isActive: isActive,
                            isNewTopic: item.isNewTopic
                        },
                        ' '
                    );
                } else {
                    return (0, _hyperapp.h)(
                        Bubble,
                        {
                            type: item.type,
                            text: item.text,
                            key: idx,
                            id: idx,
                            isNewTopic: item.isNewTopic && item.type === 'question' },
                        (0, _hyperapp.h)(_quickReplies.QuickReplies, {
                            quickReplies: item.quickReplies,
                            isActive: isActive })
                    );
                }
            }),
            loader
        );
    };
};
/* eslint-disable no-unused-vars */
var Bubble = exports.Bubble = function Bubble(_ref, extraOptions) {
    var type = _ref.type,
        text = _ref.text,
        id = _ref.id,
        isNewTopic = _ref.isNewTopic;
    return function (state, actions) {
        function handleClick(e, state) {
            if (e.target.hasAttribute('data-dcx-link')) {
                if (state.style === "medimapp") {
                    window.parent.postMessage((0, _stringify2.default)({ eventName: "openExternalUrl", target: e.target.getAttribute('data-url') }), "*");
                    console.log("posting event to window.parent with JSON object: " + (0, _stringify2.default)({ eventName: "openExternalUrl", target: e.target.getAttribute('data-url') }));
                }
                actions.handleClick({
                    interactionId: e.target.getAttribute('data-interactionid'),
                    linkUrl: e.target.getAttribute('data-url'),
                    linkId: e.target.getAttribute('data-id')
                });
            }
        }

        return (0, _hyperapp.h)(
            'div',
            { 'class': 'cxco-o-list__item cxco-o-list__bubble__item'
                //oncreate={e => myScrollToBottom(e, type === 'question')}
                , oncreate: function oncreate(e) {
                    if (state.style === "compact" || state.style === "medimapp" || state.style === "amcPopup" || state.style === "evaServiceCenter") {
                        console.log("Bubble create scroll, with style compact, or medimapp");
                        (0, _utils.scrollElement)(e, 'answer', {
                            type: state.scrollType
                        });
                    } else if (state.style !== "compact" && state.isOpen === true && state.isHidden === false) {
                        document.body.scrollIntoView(false);
                    }
                },
                onclick: function onclick(e) {
                    return handleClick(e, state);
                },
                'data-bubble-id': '' + id,
                key: 'bubble-' + id },
            (0, _hyperapp.h)(
                NewTopicDivider,
                { isNewTopic: isNewTopic, topicText: text },
                ' '
            ),
            (0, _hyperapp.h)(BubbleAvatar, { type: type }),
            (0, _hyperapp.h)('div', { 'class': 'cxco-c-bubble ' + (type === 'question' ? 'cxco-c-bubble--inverse' : '') + ' animated fadeInFast', oncreate: function oncreate(e) {
                    e.innerHTML = text;
                } }),
            extraOptions
        );
    };
};

var DialogOptions = exports.DialogOptions = function DialogOptions(_ref2) {
    var dialogOptions = _ref2.dialogOptions,
        bubbleIdx = _ref2.bubbleIdx,
        isActive = _ref2.isActive,
        isNewTopic = _ref2.isNewTopic;
    return function (state, actions) {
        if (dialogOptions && dialogOptions.length > 0) {
            return (0, _hyperapp.h)(
                'div',
                { 'class': 'cxco-o-list__item cxco-dialogOptions__item ' + (dialogOptions.find(function (x) {
                        return x.isChanging;
                    }) !== undefined ? 'cxco-o-list__item--selected' : ''),
                    oncreate: function oncreate(e) {
                        if (state.style === "compact" || state.style === "medimapp" || state.style === "amcPopup" || state.style === "evaServiceCenter") {
                            console.log("dialogOoption create scroll, with style compact, or medimapp");
                            (0, _utils.scrollElement)(e, 'answer', {
                                type: state.scrollType
                            });
                        } else if (state.style !== "compact" && state.isOpen === true && state.isHidden === false) {
                            console.log("dialogOoption create scroll, scrolling body");
                            document.body.scrollIntoView(false);
                        }
                    },
                    'data-bubble-id': '' + bubbleIdx,
                    key: 'bubble-' + bubbleIdx },
                (0, _hyperapp.h)(
                    'div',
                    { 'class': 'cxco-c-dialog ' + (isActive || dialogOptions.find(function (opt) {
                            return opt.isChanging;
                        }) !== undefined ? '' : 'selected') },
                    dialogOptions.map(function (dialogOpt, optionIdx) {
                        return (0, _hyperapp.h)(
                            'div',
                            { 'class': 'cxco-c-dialog__option ' + (dialogOpt.isSelected && !dialogOpt.isChanging ? 'selected' : '') + ' animated fadeInFast delayed',
                                onclick: function onclick(e) {
                                    if (dialogOpt.isSelected) {
                                        // ignore click on a already selected, just collapse the dialog options.
                                        actions.resetDialogOptions({ bubbleIdx: bubbleIdx, isChanging: false });

                                        return;
                                    }

                                    if (dialogOpt.isChanging) {
                                        actions.resetDialogOptions({ bubbleIdx: bubbleIdx, isChanging: false });
                                        actions.ask({ question: dialogOpt.text, dialogPath: dialogOpt.dialogPath });
                                        // TODO: investigate why the ask is not adding the question.
                                        actions.add({ value: dialogOpt.text, type: 'question', isNewTopic: true });

                                        return;
                                    }

                                    e.target.classList.add('selected');
                                    e.target.parentElement.classList.add('selected', 'slideInLeft');

                                    actions.selectDialogOption({
                                        bubbleIdx: bubbleIdx,
                                        optionIdx: optionIdx
                                    });

                                    actions.add({ value: dialogOpt.text, type: 'question' });
                                    actions.ask({ question: dialogOpt.text, dialogPath: dialogOpt.dialogPath });
                                } },
                            dialogOpt.text
                        );
                    })
                )
            );
        }
    };
};

var ResetDialog = exports.ResetDialog = function ResetDialog(_ref3) {
    var isVisible = _ref3.isVisible,
        bubbleIdx = _ref3.bubbleIdx,
        dialogOpt = _ref3.dialogOpt;
    return function (state, actions) {
        if (isVisible && dialogOpt.isSelected) {
            return (0, _hyperapp.h)(
                'a',
                { href: '#', 'class': 'cxco-c-reverter',
                    onclick: function onclick(e) {
                        e.preventDefault();
                        actions.resetDialogOptions({ bubbleIdx: bubbleIdx, isChanging: true });
                    }
                },
                (0, _hyperapp.h)(
                    'div',
                    { 'class': 'cxco-c-reverter__currentChoide cxco-c-divider' },
                    (0, _hyperapp.h)(
                        'span',
                        null,
                        dialogOpt.text
                    )
                )
            );
        }
    };
};

var NewTopicDivider = exports.NewTopicDivider = function NewTopicDivider(_ref4) {
    var isNewTopic = _ref4.isNewTopic,
        topicText = _ref4.topicText;
    return function (state, actions) {
        if (isNewTopic) {
            return (0, _hyperapp.h)(
                'div',
                { 'class': 'cxco-c-divider' },
                (0, _hyperapp.h)(
                    'span',
                    null,
                    state.dialogNewChoice,
                    ': ',
                    topicText
                )
            );
        }
    };
};

var BubbleAvatar = exports.BubbleAvatar = function BubbleAvatar(_ref5) {
    var type = _ref5.type;

    if (type !== 'question') {
        return (0, _hyperapp.h)('div', { 'class': 'cxco-c-bubble-avatar' });
    }
};