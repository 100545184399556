'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = buildResponse;
/**
 * Converts DCX API response payload into a more useful format.
 * A response could be an answer, a dialog, a t-dialog, a faq
 * @param {Object} responsePayload
 */
function buildResponse(responsePayload) {
    // TODO: should the answer + related FAQs share the same answer or should they be separated?

    return getAnswerResponse(responsePayload);
}

/**
 * Converts the DCX API response payload into a normalized object containing `data` and `metadata`.
 * Each endpoint has a different payload, so there is a lot of custom code here.
 * @param {Object} responsePayload
 */
function getAnswerResponse(responsePayload) {
    var isTDialog = responsePayload.outputType === 'TDialog';
    // could be a list of FAQs or a tree list of Categories.
    var isStructure = !responsePayload.hasOwnProperty('outputType') && !responsePayload.hasOwnProperty('outputs');

    var output = isTDialog || isStructure ? responsePayload.result : responsePayload.outputs[0];
    var type = 'answer';
    var data = {};
    var metadata = {
        sessionId: responsePayload.sessionId,
        interactionId: responsePayload.interactionId,
        culture: responsePayload.culture
    };

    if (isTDialog) {
        type = 'tdialog';
        var answer = output.messageResult.text,
            images = output.images,
            videos = output.videos,
            outputAdditions = output.metadata,
            dialogOptions = output.dialogOptions,
            links = output.links;


        data = { type: type, answer: answer, images: images, videos: videos, outputAdditions: outputAdditions, dialogOptions: dialogOptions, links: links };

        metadata.tDialogState = output.tDialogState;
        metadata.isDialogEnd = output.isEnd;
        metadata.quickReplies = output.quickReplies;
        metadata.okMessages = output.okMessages;
        metadata.slots = output.slots;
        metadata.returnEvent = output.returnEvent;
    } else if (isStructure) {
        data = {};
        if (Array.isArray(output)) {
            if (output[0] !== undefined) {
                if (output[0].hasOwnProperty('name')) {
                    data.type = 'category';
                } else {
                    data.type = 'faq';
                }

                data.result = output;
            }
        }
    } else {
        if (output) {
            // dialog metadata
            if (output.dialogPath) {
                metadata.dialogPath = output.dialogPath;
                metadata.isDialogStart = !/\//.test(output.dialogPath);
                metadata.isDialogEnd = /!$/.test(output.dialogPath);
                type = 'dialog';
            }

            // no answers frequently return escalation_1x_noanswer
            if (/^(escalation|exception)/i.test(output.interactionValue)) {
                metadata.escalation = output.interactionValue;
            }

            output.outputParts.forEach(function (outputPart) {
                if (outputPart.type === 'relatedFAQs') {
                    metadata.relatedFaqs = outputPart.faQs;
                } else {
                    var _answer = outputPart.text,
                        _images = outputPart.images,
                        _videos = outputPart.videos,
                        _outputAdditions = outputPart.metadata,
                        _dialogOptions = outputPart.dialogOptions,
                        _links = outputPart.links;


                    data = { type: type, answer: _answer, images: _images, videos: _videos, outputAdditions: _outputAdditions, dialogOptions: _dialogOptions, links: _links };
                }
            });
        }
    }

    return {
        data: data,
        metadata: metadata
    };
}