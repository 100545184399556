'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.SimpleAvatar = exports.Avatar = undefined;

var _hyperapp = require('hyperapp');

var Avatar = exports.Avatar = function Avatar() {
    return function (state, actions) {
        return (0, _hyperapp.h)('div', { 'class': 'cxco-c-avatar ' + (!state.isOpen && state.style == "compact" ? 'bounceInUpCX' : ''),
            onclick: function onclick() {
                console.log(state);
                console.log(state.messages.length);
                if (!state.messages.length > 0) {
                    actions.clearConversation({ startFromOnloadEvent: true, doRequest: true });
                }
                //actions.clearConversation({startFromOnloadEvent: false, doRequest: true}) (not sure if client wants this)
                actions.toggle();
            },
            onmouseenter: function onmouseenter(e) {
                return actions.avatarMouseEnter();
            },
            onmouseleave: function onmouseleave(e) {
                return actions.avatarMouseLeave();
            }
        });
    };
}; /* eslint-disable no-unused-vars */
var SimpleAvatar = exports.SimpleAvatar = function SimpleAvatar(_ref) {
    var isActive = _ref.isActive;
    return function (state, actions) {
        return (0, _hyperapp.h)('div', { 'class': 'cxco-c-avatar' });
    };
};