'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _iterator = require('babel-runtime/core-js/symbol/iterator');

var _iterator2 = _interopRequireDefault(_iterator);

var _symbol = require('babel-runtime/core-js/symbol');

var _symbol2 = _interopRequireDefault(_symbol);

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _typeof = typeof _symbol2.default === "function" && typeof _iterator2.default === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof _symbol2.default === "function" && obj.constructor === _symbol2.default && obj !== _symbol2.default.prototype ? "symbol" : typeof obj; };

var _extends = _assign2.default || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.actions = actions;

var _utils = require('../utils');

var _bubbleFactory = require('../bubbleFactory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var debug = require('debug')('cxco:va-actions');
var xssFilters = require('xss-filters');
var DELAY_TIME = 1500;

function actions(core) {
    /**
     * Gets the event name for the onload to call on initialization
     * @param {Object} config
     */
    var getOnloadEvent = function getOnloadEvent(config) {
        var onloadEvent = 'onload';

        try {
            if (config.page && config.page.onloadEvent) {
                onloadEvent = config.page.onloadEvent;
            }
        } catch (error) {
            debug('could\'t get the configuration', error);
        }

        return onloadEvent;
    };

    /**
     * Gets the FAQ information from config to add to the metadata
     * @param {Object} config
     */
    var getContextualFaqSettings = function getContextualFaqSettings(config) {
        var metadata = {};

        try {
            if (config.page) {
                if (config.page.minFaqs) {
                    metadata.minFaqs = config.page.minFaqs;
                }

                if (config.page.maxFaqs) {
                    metadata.maxFaqs = config.page.maxFaqs;
                }
            }

            if (config.context && config.context.classificationId) {
                metadata.classificationId = config.context.classificationId;
            }
        } catch (error) {
            debug(error);
        }

        return metadata;
    };

    var actions = {
        /**
         * Toggles between open and minimized states
         */
        toggle: function toggle(value) {
            return function (state, actions) {
                core.emitCustomEvent('chatbot.' + (!state.isOpen ? 'open' : 'minimize'));

                return {
                    isOpen: !state.isOpen
                };
            };
        },
        setStyle: function setStyle(value) {
            return function (state) {
                return { style: value };
            };
        },
        setEnvironment: function setEnvironment(value) {
            return function (state, actions) {
                core.emitCustomEvent('environment.change');

                return {
                    isEnvironment: !state.environment
                };
            };
        },

        setLoading: function setLoading() {
            var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
            return function (state) {
                return {
                    isLoading: value
                };
            };
        },

        onKeyUp: function onKeyUp(event) {
            return function (state, actions) {
                // onEnter..
                if (event.keyCode === 13) {
                    actions.ask({ question: state.text });

                    return { text: '' };
                }
            };
        },

        onInput: function onInput(event) {
            return function (state, actions) {
                return {
                    text: event.target.value
                };
            };
        },

        add: function add(value) {
            return function (state, actions) {
                // @todo: create a seperate model for this?
                var message = {
                    date: Date.now(),
                    text: value.value,
                    type: value.type,
                    isNewTopic: value.isNewTopic
                };

                return {
                    // isLoading: false,
                    messages: state.messages.concat(message)
                };
            };
        },
        clearConversation: function clearConversation(value) {
            return function (state, actions) {
                state.messages = [];
                var eventPayload = {
                    data: {
                        eventName: getOnloadEvent(core.config)
                    },
                    metadata: getContextualFaqSettings(core.config)
                };
                core.store("{}");
                if (value.doRequest === true) {
                    if (value.startFromOnloadEvent === true) {
                        core.sendEvent(eventPayload);
                    } else {
                        core.sendEvent('show_feedback');
                    }
                }
                return state;
            };
        },
        appendItem: function appendItem(bubble) {
            return function (state) {
                return { isLoading: false, messages: state.messages.concat(bubble) };
            };
        },
        createOutput: function createOutput(result) {
            return function (state, actions) {
                var bubbles = void 0;

                try {
                    bubbles = (0, _bubbleFactory.createBubbles)(result);
                } catch (e) {
                    console.error(e);
                }
                var promiseConstructor = function promiseConstructor(bubble) {
                    return function () {
                        return _promise2.default.resolve(bubble).then(actions.appendItem(bubble));
                    };
                };

                if (result.outputType === 'TDialog') {
                    state.showInput = true;
                }

                return bubbles.map(function (bubble) {
                    return promiseConstructor(bubble);
                }).reduce(function (previousPromise, nexPromise) {
                    return previousPromise.then(actions.setLoading).then((0, _utils.delay)(DELAY_TIME)).then(nexPromise);
                }, _promise2.default.resolve()).catch(function (err) {
                    return debug('err', err);
                });
            };
        },

        ask: function ask(_ref) {
            var question = _ref.question,
                dialogPath = _ref.dialogPath;
            return function (state, actions) {
                question = xssFilters.inHTMLData(question);
                if (question.replace(/\s/gm, '') === '') {
                    return { text: '' };
                }

                if (dialogPath === undefined) {
                    // only add the ask if it is not a dialog option
                    actions.add({ value: question, type: 'question' });
                }

                actions.setLoading(true);
                actions.showFaqs(false);

                var askPayload = {
                    data: {
                        userInput: question,
                        dialogPath: dialogPath
                    },
                    metadata: getContextualFaqSettings(core.config)
                };

                if (state.tDialogState && state.tDialogState !== null) {
                    askPayload.metadata.tDialogState = state.tDialogState;
                }

                core.ask(askPayload);

                return { text: '' };
            };
        },

        onAnswer: function onAnswer(answer) {
            return function (state, actions) {
                // ignore dialogstep payload because it's already stored.
                if (answer.metadata.originalRequest.type === 'dialogstep') {
                    return {};
                }

                actions.createOutput(answer).then(function () {
                    actions.setLoading(false);
                    actions.setContextualFaqs(answer);
                    // show page related faqs after onload event.
                    var onLoad = getOnloadEvent(core.config);

                    if (answer.metadata.originalRequest.data.eventName === onLoad) {
                        actions.getPageRelatedFAQs();
                    }

                    if ((0, _utils.showFeedback)(answer)) {
                        actions.startFeedback(answer);
                    } else {
                        actions.showFaqs(true);
                    }

                    if (answer.data.outputAdditions && answer.data.outputAdditions.hasOwnProperty('feedbackScore')) {
                        var score = parseInt(answer.data.outputAdditions['feedbackScore']);

                        core.sendFeedback({
                            data: {
                                score: score,
                                label: score <= 0 ? 'no' : 'yes',
                                interactionId: state.feedbackInteractionId,
                                comment: answer.data.answer.replace(/(<\/?[^>]*>)|(%{DialogOption\([^)]*\)})/gmi, '')
                            }
                        });
                    }
                });

                if (answer.data.type === 'tdialog' && !answer.metadata.isDialogEnd) {
                    actions.showInput(true);

                    return { tDialogState: answer.metadata.tDialogState };
                }
                if (answer.data.type === 'tdialog' && answer.metadata.isDialogEnd) {
                    return { tDialogState: null };
                }

                if (answer.data.type === 'category') {
                    // just use the pathname from the url
                    var nameToFind = window.location.pathname;

                    var faqs = (0, _utils.filterPageRelatedFAQs)(answer.data.result, nameToFind);

                    if (faqs && faqs.length > 0) {
                        return { showFaqs: true, faqs: faqs };
                    } else {
                        return { showFaqs: true };
                    }
                }
                if (answer.data.type === 'faq') {
                    var _faqs = answer.data.result;

                    if (_faqs && _faqs.length > 0) {
                        return { showFaqs: true, faqs: _faqs };
                    } else {
                        return { showFaqs: true };
                    }
                }
            };
        },
        startFeedback: function startFeedback(_ref2) {
            var metadata = _ref2.metadata;
            return function (state, actions) {
                core.sendEvent('show_feedback');

                return { feedbackInteractionId: metadata.interactionId };
            };
        },
        selectDialogOption: function selectDialogOption(_ref3) {
            var bubbleIdx = _ref3.bubbleIdx,
                optionIdx = _ref3.optionIdx;
            return function (state, actions) {
                var messages = state.messages.concat([]);
                var bubble = messages[bubbleIdx];

                bubble.dialogOptions.forEach(function (option, idx) {
                    option.isSelected = idx === optionIdx;
                });

                return {
                    messages: messages
                };
            };
        },

        resetDialogOptions: function resetDialogOptions(_ref4) {
            var bubbleIdx = _ref4.bubbleIdx,
                isChanging = _ref4.isChanging;
            return function (state, actions) {
                var messages = state.messages.concat([]);
                var bubble = messages[bubbleIdx];

                bubble.dialogOptions.forEach(function (option) {
                    option.isChanging = isChanging;
                });
                var path = bubble.dialogOptions[0].dialogPath;

                if (isChanging) {
                    // dialog step is needed so the 'ask' request is inside the dialog session.
                    core.dialogStep(path);
                }

                return { messages: messages };
            };
        },

        sendEvent: function sendEvent(options) {
            return function (actions) {
                return core.sendEvent(options);
            };
        },

        showInput: function showInput(value) {
            return function (state) {
                return { showInput: value };
            };
        },

        initVA: function initVA() {
            return function (state, actions) {
                var eventPayload = {
                    data: {
                        eventName: getOnloadEvent(core.config)
                    },
                    metadata: getContextualFaqSettings(core.config)
                };

                core.sendEvent(eventPayload);
            };
        },
        storeStateInStorage: function storeStateInStorage() {
            return function (state) {
                core.store(state);
            };
        },
        /**
         * Handles a link click.
         * Sends a link click event to the API
         */
        handleClick: function handleClick(_ref5) {
            var interactionId = _ref5.interactionId,
                linkUrl = _ref5.linkUrl,
                linkId = _ref5.linkId;
            return function (state, actions) {
                core.sendLinkClick({
                    data: { interactionId: interactionId, linkUrl: linkUrl, linkId: linkId }
                });
            };
        },
        setContextualFaqs: function setContextualFaqs(result) {
            return function (state, actions) {
                // TODO show loader and delay
                if (result.metadata && result.metadata.relatedFaqs) {
                    return { faqs: result.metadata.relatedFaqs };
                }
            };
        },
        getFaqAnswer: function getFaqAnswer(_ref6) {
            var id = _ref6.id,
                question = _ref6.question;
            return function (state, actions) {
                actions.add({ value: question, type: 'question' });
                core.faqAsk(id);
            };
        },
        getPageRelatedFAQs: function getPageRelatedFAQs() {
            return function (state, actions) {
                var page = core.config.page || {};

                if (page.hasOwnProperty('classificationId')) {
                    var faqPayload = {
                        data: {
                            classificationId: page.classificationId
                        },
                        metadata: {}
                    };

                    if (page.hasOwnProperty('categoryId')) {
                        // get from class + cat
                        faqPayload.data.categoryId = page.categoryId;
                        core.getFaqsByCategoryId(faqPayload);
                    } else {
                        core.getAllFaqs(faqPayload);
                        // get from tree structure
                    }
                }
            };
        },
        showFaqs: function showFaqs(shouldShow, faqs) {
            return function (state, actions) {
                return _extends({ showFaqs: shouldShow }, faqs && { faqs: faqs });
            };
        },
        botReady: function botReady(e) {
            return function () {
                core.emitCustomEvent('chatbot.ready', e);
            };
        },
        avatarMouseEnter: function avatarMouseEnter(e) {
            return function () {
                core.emitCustomEvent('avatar.mouseEnter', e);
            };
        },
        avatarMouseLeave: function avatarMouseLeave(e) {
            return function () {
                core.emitCustomEvent('avatar.mouseLeave', e);
            };
        },
        closeMouseEnter: function closeMouseEnter(e) {
            return function () {
                core.emitCustomEvent('closeButton.mouseEnter', e);
            };
        },
        closeMouseLeave: function closeMouseLeave(e) {
            return function () {
                core.emitCustomEvent('closeButton.mouseLeave', e);
            };
        },
        changeState: function changeState(payload) {
            return function (state, actions) {
                var partialState = {};

                if ((typeof payload === 'undefined' ? 'undefined' : _typeof(payload)) !== 'object') {
                    debug('invalid state payload');

                    return partialState;
                }
                var EDITABLE_PROPERTIES = ['isOpen', 'isHidden', 'environment', 'template'];
                (0, _keys2.default)(payload).forEach(function (key) {
                    if (EDITABLE_PROPERTIES.indexOf(key) >= 0) {
                        debug('overrided state setting: ' + key);
                        partialState[key] = payload[key];
                    }
                });

                return partialState;
            };
        }
    };

    return { actions: actions, core: core };
}