'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Loader = undefined;

var _hyperapp = require('hyperapp');

var Loader = exports.Loader = function Loader() {
    return function (state, actions) {
        //  if (state.isLoading) {
        return (0, _hyperapp.h)(
            'div',
            { 'class': 'cxco-c-loader ' + (state.isLoading ? 'is-visible' : '') },
            (0, _hyperapp.h)('div', { 'class': 'cxco-c-loader__bubble' }),
            (0, _hyperapp.h)('div', { 'class': 'cxco-c-loader__bubble' }),
            (0, _hyperapp.h)('div', { 'class': 'cxco-c-loader__bubble' })
        );
        //  }
    };
}; /* eslint-disable no-unused-vars */