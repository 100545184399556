'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _defineProperty = require('babel-runtime/core-js/object/define-property');

var _defineProperty2 = _interopRequireDefault(_defineProperty);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _extends = _assign2.default || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; (0, _defineProperty2.default)(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _cxco = require('./cxco');

var _cxco2 = _interopRequireDefault(_cxco);

var _eventstop2 = require('eventstop');

var _eventstop3 = _interopRequireDefault(_eventstop2);

var _beforeAfterHook = require('before-after-hook');

var _beforeAfterHook2 = _interopRequireDefault(_beforeAfterHook);

var _storage = require('./storage');

var _utils = require('./utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var debug = require('debug')('cxco:core');

var _eventstop = (0, _eventstop3.default)(),
    on = _eventstop.on,
    emit = _eventstop.emit;
// import CxcoException from './cxcoException'

require('./polyfills');
var window = require('global/window');
var hook = new _beforeAfterHook2.default();

/**
 * CxcoCore - the main class to be instantiated.
 * Exposes methods to perform actions (ask, sendEvent, etc...) and allows plugins to listen and modify them.
 * @param {Object} options - get configuration from window or from arguments
 */

var CxcoCore = function () {
    function CxcoCore(options) {
        _classCallCheck(this, CxcoCore);

        this.on = on;
        this.before = hook.before;
        var conf = options.useWindowConfig ? window.cxcoConfig || {} : options.config || {};

        this.config = (0, _utils.normalizeConfig)(conf);

        if (options.useWindowApi) {
            this.initWindowApi();
        }
    }

    _createClass(CxcoCore, [{
        key: 'initWindowApi',
        value: function initWindowApi() {
            this.windowApi = new _cxco2.default(this);
        }
    }, {
        key: 'init',
        value: function init() {
            // @todo process installed plugins
        }
    }, {
        key: 'use',
        value: function use(plugin) {
            plugin.install(this);
        }
    }, {
        key: 'getConfig',
        value: function getConfig() {
            if ((0, _keys2.default)(this.config).length === 0 && this.config.constructor === Object) {
                debug('Configuration object not provided...');
                // TODO: add condition to check if should use window config
                if (window && window.cxcoConfig) {
                    this.config = (0, _utils.normalizeConfig)(window.cxcoConfig);
                    debug('loaded new configuration from window');
                }
            }

            return this.config;
        }

        /**
         * Ask API. Emits an core 'ask' event.
         * @param {Object} askPayload
         */

    }, {
        key: 'ask',
        value: function ask(askPayload) {
            var _this = this;

            if (typeof askPayload === 'string') {
                askPayload = {
                    data: {
                        userInput: askPayload
                    }
                };
            }

            askPayload = this.addPayloadConfig(askPayload);
            askPayload = this.addPayloadMetadata(askPayload);

            return hook('ask', askPayload, function (askOptions) {
                emit('ask', askOptions);

                return _this;
            });
        }
    }, {
        key: 'clearConversation',
        value: function clearConversation(clearConversationPayload) {
            if (typeof clearConversationPayload === 'string') {
                console.log("needs object, received string. Make sure to include reInitOption, reInitConversation, doApiCall");
            } else if (typeof clearConversationPayload === "undefined") {
                clearConversationPayload = { doRequest: true, startFromOnloadEvent: true };
            }
            emit('clearConversation', clearConversationPayload);
        }
    }, {
        key: 'dialogStep',
        value: function dialogStep(dsPayload) {
            if (typeof dsPayload === 'string') {
                dsPayload = {
                    data: {
                        dialogPath: dsPayload
                    }
                };
            }

            dsPayload = this.addPayloadConfig(dsPayload);
            dsPayload = this.addPayloadMetadata(dsPayload);

            emit('dialogstep', dsPayload);
        }
        /**
         * Send Event to API. Emits an core 'event' event.
         * @param {*} eventPayload
         */

    }, {
        key: 'sendEvent',
        value: function sendEvent(eventPayload) {
            if (typeof eventPayload === 'string') {
                eventPayload = {
                    data: {
                        eventName: eventPayload
                    }
                };
            }

            eventPayload = this.addPayloadConfig(eventPayload);
            eventPayload = this.addPayloadMetadata(eventPayload);

            emit('event', eventPayload);

            return this;
        }

        /**
         * Emits a core 'linkclick' event.
         * @param {Object} eventPayload - e.g. {
                    data: { interactionId, linkUrl, linkId}
                }
         */

    }, {
        key: 'sendLinkClick',
        value: function sendLinkClick(eventPayload) {
            eventPayload = this.addPayloadConfig(eventPayload);
            eventPayload = this.addPayloadMetadata(eventPayload);

            emit('linkclick', eventPayload);

            return this;
        }

        /**
         * Emits a core 'feedback' event.
         * @param {Object} eventPayload
         */

    }, {
        key: 'sendFeedback',
        value: function sendFeedback(eventPayload) {
            eventPayload = this.addPayloadConfig(eventPayload);
            eventPayload = this.addPayloadMetadata(eventPayload);

            emit('feedback', eventPayload);

            return this;
        }

        /**
         * High-Order function that sends the original API request to the answer
         * @param {Object} originalRequest
         */

    }, {
        key: 'emitAnswerWith',
        value: function emitAnswerWith(originalRequest) {
            var _this2 = this;

            return function (answer) {
                return _this2.answer(answer, originalRequest);
            };
        }

        /**
         * Adds original API request to the answer and creates/updates session id.
         * Uses hook to allow for before/after `core.before('answer', callback)` operations.
         * Emits core 'answer' event.
         * @param {Object} answerPayload
         * @param {Object} request
         */

    }, {
        key: 'answer',
        value: function answer(answerPayload, request) {
            answerPayload.metadata.originalRequest = request;

            this.setOrUpdateSessionId(answerPayload.metadata.sessionId);

            return hook('answer', answerPayload, function (answer) {
                emit('answer', answer);

                return answer;
            });
        }

        /**
         * Emits core 'faq' event to retrieve
         * the answer to a specific FAQ identified by `faqId`.
         * @param {Object} faqPayload
         */

    }, {
        key: 'faqAsk',
        value: function faqAsk(faqPayload) {
            if (typeof faqPayload === 'string' || typeof faqPayload === 'number') {
                faqPayload = {
                    data: {
                        faqId: faqPayload
                    }
                };
            }

            faqPayload = this.addPayloadConfig(faqPayload);
            faqPayload = this.addPayloadMetadata(faqPayload);
            emit('faq', faqPayload);
        }

        /**
         * Emits core 'categorytree' event
         * @param {Object} faqPayload
         */

    }, {
        key: 'getAllFaqs',
        value: function getAllFaqs(faqPayload) {
            faqPayload = this.addPayloadConfig(faqPayload);
            faqPayload = this.addPayloadMetadata(faqPayload);
            emit('categorytree', faqPayload);
        }
        /**
         * Emits core 'faqsincategory' event
         * @param {Object} faqPayload
         */

    }, {
        key: 'getFaqsByCategoryId',
        value: function getFaqsByCategoryId(faqPayload) {
            faqPayload = this.addPayloadConfig(faqPayload);
            faqPayload = this.addPayloadMetadata(faqPayload);
            emit('faqsincategory', faqPayload);
        }
        /**
         * Stores value in general storage.
         * @param {Object} value
         */

    }, {
        key: 'store',
        value: function store(value) {
            // TODO: improvement - storage name could be overridable by configuration.
            (0, _storage.setStorage)(_storage.store.GENERAL, value);
        }

        /**
         * Emits core 'showInput' event.
         * @param {boolean} isInputVisible
         */

    }, {
        key: 'showInput',
        value: function showInput(isInputVisible) {
            emit('showInput', isInputVisible);

            return isInputVisible;
        }
    }, {
        key: 'error',
        value: function error(_error) {
            this.errorWith(_error);
        }
    }, {
        key: 'errorWith',
        value: function errorWith(error, payload) {
            emit('error', error, payload);

            console.error(error, payload);
        }

        /**
         * Adds config to request payload
         * @param {Object} payload
         */

    }, {
        key: 'addPayloadConfig',
        value: function addPayloadConfig(payload) {
            var config = this.getConfig();

            var engineUrl = config.engineUrl;
            var _config$project = config.project,
                customerKey = _config$project.customerKey,
                key = _config$project.key;


            if (engineUrl) {
                // this should be for internal use only. Customers should use session.mode=test because of server load reasons.
                if (engineUrl.toLowerCase() === 'cms') {
                    engineUrl = 'https://dev.digitalcx.com/' + customerKey + '/projects/' + key;
                } else if (engineUrl.toLowerCase() === 'staging') {
                    engineUrl = 'https://' + customerKey + '-' + key + '.staging.digitalcx.com';
                } else if (engineUrl.toLowerCase() === 'test') {
                    engineUrl = 'https://' + customerKey + '-' + key + '.digitalcx.com';

                    if (payload.metadata) {
                        payload.metadata.sessionMode = 'test';
                    } else {
                        payload.metadata = { sessionMode: 'test' };
                    }
                }
                // proxy
                // https://${customerKey}-${key}.cxcompany.com/
            } else {
                // production
                engineUrl = 'https://' + customerKey + '-' + key + '.digitalcx.com';
            }

            return _extends({
                config: {
                    baseUrl: engineUrl, /* e.g. 'https://dev.digitalcx.com/cxcompany/projects/devdemos' */
                    apiKey: config.project.apiKey,
                    culture: config.project.culture
                }
            }, payload);
        }
        /**
         * Adds metadata to payload.
         * @param {Object} payload
         */

    }, {
        key: 'addPayloadMetadata',
        value: function addPayloadMetadata(payload) {
            var dimensions = void 0;

            if (this.config && this.config.context) {
                dimensions = this.config.context.dimensions;
            }

            return _extends({}, payload, {
                metadata: _extends({
                    dimensions: dimensions
                }, payload.metadata, {
                    sessionId: this.getSessionId()
                })
            });
        }

        /**
         * Get Session Id from storage
         */

    }, {
        key: 'getSessionId',
        value: function getSessionId() {
            // TODO: improvement store name in config
            var sessionId = (0, _storage.getStorage)(_storage.store.SESSION_ID);

            return sessionId;
        }

        /**
         * Update Session Id in session storage.
         * @param {string} sessionId
         */

    }, {
        key: 'setOrUpdateSessionId',
        value: function setOrUpdateSessionId(sessionId) {
            (0, _storage.setStorage)(_storage.store.SESSION_ID, sessionId);
        }

        /**
         * Get State from SessionStorage
         *
         * @returns {Object} state
         */

    }, {
        key: 'getState',
        value: function getState() {
            return (0, _storage.getStorage)(_storage.store.GENERAL);
        }
    }, {
        key: 'emitCustomEvent',
        value: function emitCustomEvent(eventName, message) {
            emit(eventName, message);
        }
    }, {
        key: 'changeState',
        value: function changeState(statePayload) {
            debug('changeState', statePayload);
            emit('changeState', statePayload);
        }
    }]);

    return CxcoCore;
}();

exports.default = CxcoCore;