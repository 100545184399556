'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

exports.normalizeConfig = normalizeConfig;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var debug = require('debug')('cxco:core');

/**
 * Defines which settings are being used from the config
 * @param {Object} conf
 */
function normalizeConfig(conf) {
    var internalConf = {
        project: {}

        /* GLOBAL SETTINGS */

        // version is optional
    };if (conf.version) {
        internalConf.version = conf.version;
    }

    // parentSelector is optional
    if (conf.parentSelector) {
        internalConf.parentSelector = conf.parentSelector;
    }

    // project properties are mandatory
    if (conf.project) {
        if (conf.project.apiKey || conf.project.publicApiKey) {
            internalConf.project.apiKey = conf.project.apiKey || conf.project.publicApiKey;
        } else {
            debug('missing mandatory configuration parameter: publicApiKey');
        }

        if (conf.project.culture) {
            internalConf.project.culture = conf.project.culture;
        } else {
            debug('missing mandatory configuration parameter: culture');
        }
        if (conf.project.customerKey) {
            internalConf.project.customerKey = conf.project.customerKey;
        } else {
            debug('missing mandatory configuration parameter: customerKey');
        }
        if (conf.project.key) {
            internalConf.project.key = conf.project.key;
        } else {
            debug('missing mandatory configuration parameter: key');
        }
    } else {
        debug('missing mandatory configuration parameter: project');

        return conf;
    }

    // engineUrl is optional
    if (conf.engineUrl) {
        internalConf.engineUrl = conf.engineUrl;
    }
    // page is optional
    if (conf.page || conf.pageSettings) {
        // change in API
        internalConf.page = conf.page || conf.pageSettings;

        if (conf.pageSettings && conf.pageSettings.hasOwnProperty('showInput')) {
            internalConf.layout = {
                showInput: conf.pageSettings.showInput
            };
        }

        if (internalConf.page.hasOwnProperty('showInput')) {
            delete internalConf.page.showInput;
        }
    }

    // context is optional
    if (conf.context || conf.contextSettings) {
        // change in API
        internalConf.context = conf.context || conf.contextSettings;

        if (internalConf.context.dimensions) {
            if (internalConf.context.dimensions.length === 0 || internalConf.context.dimensions.some(function (dim) {
                return typeof dim['Implementation'] === 'undefined';
            })) {
                internalConf.context.dimensions.push({ 'Implementation': 'DCI' });
            }
        } else {
            internalConf.context.dimensions = [{ 'Implementation': 'DCI' }];
        }
    } else {
        internalConf.context = {
            dimensions: [{ 'Implementation': 'DCI' }]
        };
    }

    // layout is optional
    if (conf.layout) {
        internalConf.layout = (0, _assign2.default)(conf.layout, internalConf.layout);
    }

    return internalConf;
}